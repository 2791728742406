<template>

<div class="profile">

	<div class="profile-image" v-bgimage="block.image"></div>

	<div class="profile-text">

		<h2>{{ block.name }}</h2>

		<div class="profile-text-price">{{ block.currency }}{{ block.price }}</div>

		<div v-html="block.embed" v-if="!window.is.mobile" />

		<p v-if="!window.is.mobile">{{ block.text }}</p>

	</div>

	<div class="profile-text-full" v-if="window.is.mobile">

		<div v-html="block.embed" />

		<p>{{ block.text }}</p>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.profile {
	display: flex;
	margin-bottom: 20px;
}

.is-mobile .profile {
	flex-wrap: wrap;
}

.profile-text {
	flex-grow: 1;
	padding-left: 80px;
}

.profile-text-full  {
	width: 100%;
	padding-top: 20px;
}

.is-device .profile-text {
	padding-left: 20px;
	width: calc(100% - 140px - 30px);
}

.profile-text h2 {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 20px;
	color: #1a234c;
}

.is-device .profile-text h2 {
	font-size: 20px;
}

.is-mobile .profile-text h2 {
	font-size: 16px;
	line-height: 24px;
}

.profile-text-price {
	color: #1a234c;
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 20px;
}

.profile-text p {
	font-size: 16px;
	line-height: 30px;
	color: #1a234c;
}

.is-mobile .profile-text-full p {
	font-size: 14px;
	line-height: 20px;
}

.profile-image {
	flex-shrink: 0;
	width: 400px;
	border-radius: 50%;
	border: 14px solid #b7b7b7;
	height: 400px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.is-device .profile-image {
	width: 180px;
	height: 180px;
	border-width: 10px;
}

.is-mobile .profile-image {
	width: 140px;
	height: 140px;
	border-width: 6px;
}

</style>
